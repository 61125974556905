body{
    overflow-x: hidden;
}
.bg-dark{
    background-color:$black-light!important;
}
.container-fluid{
    max-width: 1400px;
}

#nav{
    background-color: transparent;
    transition: .3s all;
    .nav-item{
        padding-top:10px;
        transition: .3s all;
        .nav-link{
            color:$grey;
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            font-family: 'Montserrat',sans-serif;
            &.active{
                color:$blue-light;   
                text-decoration: underline;
            }
        }
    }
    &.scrolled{
        padding-top:0;
        background-color: white;
    }
}

.border-radius{
    border-radius: 15px;
    overflow: hidden;
}

.bg-gray{
    background-color: $grey-light;
}

.photo{
    background-position: center;
    background-size: cover;
    min-height: 535px;
}

.navbar-toggler{
    border:0;
    outline: none;
    &.open{
        .navbar-toggler-icon{
            z-index: 1000;
            .white{
                display: block;
                z-index: 1001;
                position: relative;
                top:-20px;
            }
        }
    }
    @media screen and (max-width:600px) {
        margin-left:auto;
    }
    &:focus,&:active{
        outline: hidden;
    }

}

.navbar-toggler-icon{    
   &.open{
    .blue{
        display: block;
    }
    .white{
        display: none;
    }
   }
}

@media screen and (max-width:600px) {
    .navbar{
        padding:0;
        .navbar-toggler-icon{
            margin-top:8px;
            .white{
                display: none;
            }
            &.open{
                .blue{
                    display: none;
                }
                .white{
                    display: block;
                }
            }
        }
        .navbar-collapse{
            &.show{
                background-color: $blue-light;
                color:white;
                margin-top:-50px;
                .navbar-nav{
                    height:100vh;
                    justify-content: center;
                    .nav-item{
                        text-align: center;
                        .nav-link{
                            color:white!important;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    }
}
.alert{
    display: none;
    color:white;
}

.icons{
    width:24px;
    height:24px;
}

.logo-nav{
    width: 100px;
    height: auto;
    padding: 10px 10px 10px 20px;
    @media screen and (max-width:600px){
        width: 80px;
        height: auto;        
        position: absolute;
        left: 0;
        right: 10px;
        top: 5px;
        display: block;
        margin: 0 auto;
    }
}
// .nav-item{
//     @media screen and (min-width:993px){
//         padding:8px 15px;
//     }
//     .nav-link{
//         color:white!important;
//         font-family: "Cera Pro Light";
//         text-transform: uppercase;
//         // font-weight: 600;
//         @media screen and (max-width:600px){
//             text-align: center;
//             margin:auto;
//         }
//     }
// }

// banner
#inicio{   
    min-height: 700px;
    @media screen and (max-width:600px){
        min-height: 620px;        
    }
    .carousel-inner{
        .carousel-item{
            min-height: 700px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            @media screen and (max-width:600px){
                min-height: 620px;
                background-position-x: 80%;
            }
            .carousel-caption{
                top:15%;
                bottom:inherit; 
                left:3%;
                right:inherit;       
                display: flex;        
                flex-direction: column;
                @media screen and (max-width:600px){
                    top:-5px;
                    left:1%;
                    height: 620px;
                    width:100%;
                }
                .border-text{
                    background-color:$blue-light;
                    border-radius: 0px 0px 100px 0px;
                    width:450px;
                    height:200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: left;
                    @media screen and (max-width:600px){
                        width:240px;
                        height:120px;
                        left:2%;       
                        margin-left:10px;                 
                    }
                    h1{
                        @media screen and (max-width:600px){
                            font-size: 1.4rem;
                            margin-left:-30px;
                        }
                    }
                    h2{
                        font-weight: 300;
                        @media screen and (max-width:600px){
                            font-size: 1.1em;
                            margin-left:-30px;
                        }
                    }
                }
                .wsp-btn{
                    width:420px;
                    height:auto;
                    margin-top:30px;
                    margin-left:-30px;                    
                    @media screen and (max-width:600px){
                        width:80%;
                        margin-left:0px;
                        position: absolute;
                        bottom:13%;
                        display: block;
                        margin:0 auto;
                        left:0;
                        right:0;


                    }
                }
                .tlf-btn{
                    width:450px;
                    height:auto;
                    margin-top:10px;
                    margin-left:0px;                    
                    @media screen and (max-width:600px){
                        width:90%;
                        margin-left:0px;
                        position: absolute;
                        bottom:3%;
                        display: block;
                        margin:0 auto;
                        left:0;
                        right:0;
                    }
                }
            }
        }
    }
    @media screen and (min-width:1600px){
        max-width:1920px;
    } 
    .carousel-indicators{
        li{
            width:10px;
            height: 10px;
            border-radius: 100%;
        }
    }
}

.box-blue{
    background-color: $blue-light;
    position: relative;
    padding: 15px;    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    h3{
        color:white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        opacity: 1;
        transition: .3s all;        
        font-size: 1.7rem;
        padding: 15px;
        height: 240px;
        @media screen and (max-width:600px){
            font-size:1rem;
            padding:6px;
            height:130px;
        }
    }
    .overlay{        
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
        background-color: #282828b8;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        p{
            color:white;
            text-align: center;
            font-size: 1.2rem;
            padding:15px;
            @media screen and (max-width:600px){
                font-size: .75rem;
                padding:5px;
            }
               
        }
    }
    &:hover{
        .overlay{
            opacity: 1;
        }
        h3{
            opacity: 0;

        }
    }
}

#patologias{
    .row{
        @media screen and (max-width:600px){
            justify-content: center;
        }
    }
}

.accordion{
    .card{
        border: 0;
        margin-bottom: 10px;        
        .card-header{
            border: 0;
            background-color: $blue-light;
            border-radius: 20px!important;
            @media screen and (max-width:600px){
                border-radius: 15px!important;
                padding:6px 5px;
            }
            h2{
                .btn{
                    color:white;                
                    position: relative;
                    transform: .3s all;
                    &:focus,&:active{
                        text-decoration: none;
                    }
                   span{
                        background-position-y: center;
                        background-position-x: 95%;
                        background-size: 25px;
                        background-repeat: no-repeat;                    
                        position: absolute;
                        right:15px;
                        top:25%;
                        width: 25px;
                        height: 25px;
                        transform: .3s all;
                        transform: rotate(180deg);
                        @media screen and (max-width:600px){
                            width:15px;
                            height:15px;
                            background-size: 15px;
                        }
                   }
                   &.collapsed{
                    transform: .3s all;
                       span{
                        transform: rotate(0deg);
                       }
                   }
                }
            }
        }
        .card-body{
            .card-list{
                li{
                    list-style: none;
                    position: relative;
                    padding-bottom: 10px;
                    &:before{
                       content:'';
                       width:10px;
                       height:10px;
                       display:inline-block;
                       position: absolute;
                       left: -15px;
                       top: 8px;
                       background-color: $blue-light;
                       border-radius: 100%;
                    }
                }
            }
        }
    }
    @media screen and (max-width:600px){
        margin-top:50px;
    }
}

.section-form{
    margin:5% 0;
    position: relative;
    .img-bg{
        background-size: cover;
        width:100%;
        height:625px;
        display: block;
        z-index: 2;
        position: relative;
        background-position: center center;
        &.invert{
            transform: scaleX(-1);
            background-position-y: 29%;
            @media screen and (max-width:600px) {
                background-position-y: 75%;
            }
        }
        &.who{
            background-position-y: 90%;
        }
        @media screen and (max-width:600px) {
            height: 200px;
            width: 70%;
            &.lm{
                left:-25px;
            }        
            &.rm{
                position: absolute;
                right: 0;
                text-align: center;
            }
        }
        &.our-team{
            height:650px;
            @media screen and (max-width:600px) {
                height: 200px;
                left: -25px
            }
        }
    }
    .border-gold{
        border:2px solid $gold;
        padding: 10% 10% 10% 15%;
        min-height: 350px;
        margin-top:25px;        
        z-index: 1;
        &.t-left{
            margin-left:-70px;          
            @media screen and (max-width:600px) {
                margin-left:0;
                padding:110px 7% 7% 7%;
                margin-top:-150px;
            }  
        }
        &.t-right{
            margin-right:-100px; 
            @media screen and (max-width:600px) {
                margin-right:0;
                padding:10% 10% 10% 10%;
                p{
                    padding-top:90px;
                }
            }       
        }
        .bg-white-gold{
            background-color:white;
            color:$gold;
            font-size:2.8rem;
            position:relative;            
            z-index: 3;
            padding:25px;
            font-family: 'Montserrat', sans-serif;
            margin-bottom:15px;
            font-weight: 600;
            &.t-left{
                left:-100px;
                text-align: left;
                @media screen and (max-width:600px) {
                    font-size:1.4rem;
                    left:120px;
                    padding:5px 10px;
                    top:-50px;
                    width:fit-content;
                }
            }
            &.t-right{
                right:-80px;
                text-align: right;
                @media screen and (max-width:600px) {
                    right:30px;
                    font-size:1.4rem;
                    padding:5px 10px;
                    width:150px;
                }
            }
        }
        p{
            color:black;
            font-family: "Cera Pro Light";
            font-size: 1rem;              
            text-align: justify;      
            @media screen and (max-width:600px) {
                font-size:.8rem;                
            }   
        }
        .know-more{
            background-color: $gold;
            color:white;
            padding: 6px 20px;
            text-decoration: none;
            font-family: "Cera Pro Light";                        
            font-weight: 600;
            width:150px;
            height:36px;
            display:block;
            text-align: center;
            letter-spacing: 1.5px;    
            -webkit-font-smoothing: subpixel-antialiased;    
            &.t-right{
                display: block;
                margin: auto;
                margin-right:10%;
                @media screen and (max-width:600px) {
                    margin-right: auto;
                    width:165px;
                }
            }
            &.rm{
                display: flex;
                justify-content: center;
                margin: auto;
                margin-right: 3rem;    
                text-align: center;    
                letter-spacing: 1.5px;        
                @media screen and (max-width:600px) {
                    margin-right:auto;
                }
            }
            @media screen and (max-width:600px) {
                margin:auto;
                margin-right: auto;
                display: block;
                font-size: .9rem;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width:600px) {
        .col-12{
            padding:0px;            
        }
        .photo{
            order:-1;
            min-height: 380px;
            margin-bottom: 30px;
        }
    }
}

#quien-soy-yo{
    @media screen and (max-width:600px) {
        .text-content{
            order:2;
        }
    }
}

#nuestro-equipo{
    .know-more{
        position: relative;
        top:25px;
        text-align: center;
        font-family: "Cera Pro Light";        
        font-weight: 600;
        letter-spacing: 1.5px;
        -webkit-font-smoothing: subpixel-antialiased;
        @media screen and (max-width:600px) {
            top:0;
            font-size: .9rem;
        }
    }
}

.section-whatwedo{
    border:2px solid $gold;
    padding:2% 6%;
    .bg-white-gold{
        background-color:white;
        color:$gold;
        font-size:2.8rem;
        position:relative;            
        z-index: 3;
        padding:25px;
        font-family: 'Montserrat', sans-serif;   
        font-weight: 600;     
        text-align: center;
        @media screen and (max-width:600px) {
            font-size: 1.4rem;
        }
    }
    p{
        font-family: "Cera Pro Light";
        color:black;        
        @media screen and (max-width:600px) {
            font-size: .8rem;
        }
    }
}
.section-knowus{
    margin-top:5%;
    margin-bottom:5%;
    .bg-white-gold{
        background-color:white;
        color:$gold;
        font-size:2.8rem;
        position:relative;            
        z-index: 3;
        padding:25px;
        font-family: 'Montserrat', sans-serif;        
        text-align: center;
        font-weight: 600;
        @media screen and (max-width:600px) {
            font-size: 1.45rem;
        }
    }
    p{
        font-family: "Cera Pro Light";
        color:black;    
        @media screen and (max-width:600px) {
            font-size: .8rem;
        }    
    }
    .know-box{
        width: 100%;
        min-height: 600px;
        display: block;
        border:15px solid white;
        outline: inherit;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        &.contenedores{
            background-position-x: 25%;
        }
        @media screen and (max-width:600px) {
            border:0;
            margin-top: 20px;
        }
        .btn-top{
            background-color:$black-light;
            color:white;
            padding:13px 20px;
            border-radius:0;
            margin:auto;
            display: block;
            margin-top:20px;
            width:85%;
            font-family: "Cera Pro Light";                    
            font-weight: 600;
            text-align: center;
            letter-spacing: 1.5px;
            &:hover{
                color:white;
            }
        }
        .btn-down{
            background-color:$gold;
            color:white;
            padding:13px 20px;
            border-radius:0;
            margin:auto;
            display: block;
            position:absolute;
            left:0;
            right: 0;
            bottom:20px;
            letter-spacing: 1.5px;
            width:85%;
            font-family: "Cera Pro Light";                      
            font-weight: 600;
            @media screen and (max-width:600px) {
                width:60%;
            }
        }
    }
}

#contacto{
    margin-top:200px;
    margin-bottom: 80px;
    @media screen and (max-width:600px) {
        margin-top:80px;
        padding:15px;
        margin-bottom: 30px;
    }
    .col-contacts{
        width:100%;
        min-height: 470px;
        margin-bottom: 20px;
        @media screen and (max-width:600px) {
            min-height: 530px;
            text-align: center;
            padding: 0;
            margin-bottom: -50px;
        }        
      .form-box{
          background-color: $blue-light-2;
          padding: 15px;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          align-items: center;
          min-height: 480px;
          h2{
              text-align: center;
              font-weight: 800;
              color:white;
              @media screen and (max-width:600px){
                  padding:0px 0px 25px;
              }
          }
          .form-control{
              border-radius: 20px;
              border:0;
              min-height: 45px;
              @media screen and (max-width:600px){
                min-height: 35px;
              }
          }
          textarea{
              border-radius: 25px;
              border:0;
          }
          label{
              color:white;
              font-family: "Montserrat",sans-serif;
              font-weight: 600;
              padding-right: 10px;
              @media screen and (max-width:600px){
                  position: relative;
                  left:-15px;
                  top:5px;
              }
          }
          .btn-send{
              background-color: white;
              color:$blue-light;
              font-family: "Montserrat",sans-serif;
              font-weight: 600;
              border-radius: 15px;
              padding:8px 30px;
              width:150px;
              margin:auto;
              margin-right:0;
              display: flex;
              justify-content: center;
              &:hover{
                background-color: white;
                color:$blue-light;
              }
          }
      }
      &.photo-contact{
          min-height: 480px;
          background-image: center;
          background-size: cover;
          @media screen and (max-width:600px) {
              order:-1;
              min-height: 360px;
              margin-bottom: 30px;
          }
      }
    }

}


.bottom-information{    
    padding:0px;
    min-height:400px;
    margin-bottom:25px;
    @media screen and (max-width:600px){
        padding:20px;
        padding-bottom: 0px;
    }
    .blue-content{
        width:100%;
        height:100%;
        background-color:#39adc1;
        border-radius: 15px; 
        padding:15px;
        display: flex;   
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-direction: column;
        p{
            color:white;
            font-family: "Cera Pro Light";   
            font-size: 25px;
            padding:0px 25px;
            text-align: center;
        }
        .directions{
            width:100%;
            display: flex;
            ul{
                list-style: none;
                margin:0 auto;
                padding-left:0;
                li{
                    padding-left:0;
                    text-align: left;
                    color:white;
                    font-family: "Cera Pro Light";   
                    font-size: 25px;
                    img{
                        width:20px;
                        margin-right: 10px;
                    }
                    span{
                        position: relative;
                        left:30px;
                    }
                }
            }
        }
    }
    .big-icon{
        width:80px;
        height:auto;
        margin-bottom:20px;
        &.map{
            width:60px;
        }
    }
}

.foot{
    background-color: $blue-light;
    width: 100%;
    min-height: 60px;
    padding:10px;
    @media screen and (max-width:600px){
        min-height: 30px;
        padding: 5px 5px 0;
        .row{
            padding-right:0;
        }
    }
    p{
        color:white;
        padding:3px 0px;
        font-family: "Montserrat",sans-serif;
        a{
            color: white;       
            font-family: "Montserrat",sans-serif;
         }        
        @media screen and (max-width:600px) {
            font-size: .65rem;
            padding-bottom: 0;
        }
    }
    a{
        font-family: "Cera Pro Light";        
        img{
            width:16px;
            height: 16px;
        }
    }
    .logo-large{
        width: auto;
        height: 20px;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        display: block;
        @media screen and (max-width:600px) {
            height: 12px;
        }
    }
}
//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ... 
// 1. Global
// ---------


/*!
 * Primary color settings
 */

$blue-light: #39adc1;
$blue-light-2:#7dd6e4;
$blue-dark: #0c202f;
$red: #E80029;
$black: #282828;
$white: #fff;
$grey:#a9a9a9;
$grey-light:#efeeee;
$grey-x-light:#f1f1f1;
$grey-medium:#8c8c8c;
$grey-dark:#5a5a5a;
$grey-form:#C8C8C8;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1140px) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// colores
$gold:#e5ad4a;
$black-light:#3b3c3b;

//fonts

$font_path: '/assets/fonts/';

html {
    scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
}
.title{
    color:$blue-light;
    font-size: 2rem;
    text-align: center;
    padding: 30px 0px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

p{
    font-family: 'Raleway', sans-serif;
    color:$black;
}